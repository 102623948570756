import * as React from 'react';

import Layout from '../components/global/layout/layout';
import { PageBase } from '../context/global-context';
import { ErrorContext, ErrorLocalizationData } from '../types/error';
import { getLocales, useI18n } from '../utils/i18n';
import './404.scss';

const NotFoundPage: React.FC<{
  pageContext: ErrorContext;
}> = ({ pageContext }) => {
  const { translations, locale } = pageContext;
  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: ErrorLocalizationData[] = [];

  locales.forEach((l) => {
    localizationsData.push({
      attributes: {
        locale: l,
        error: '404',
      },
    });
  });

  const notFound = {
    attributes: {
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };
  return (
    <PageBase translations={translations} instance={notFound}>
      <Layout>
        <div className="not-found">
          {locale == 'fr' ? (
            <h1>404: Page Introuvable</h1>
          ) : (
            <>
              <h1>404: Not Found</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </>
          )}
        </div>
      </Layout>
    </PageBase>
  );
};

export default NotFoundPage;
